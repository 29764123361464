.tool-ul {
	list-style: none;
	padding-left: 0px;
}

.project-text {
	text-align: justify;
	text-justify: inter-word;
}

.links-ul {
	list-style: none;
	padding-left: 0px;
}

.tech-icon {
	max-height: 20px;
	max-width: 20px;
	padding-right: 5px;
}

/* Responsive Youtube */

.resp-container {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
}

.resp-iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}
