/* HOME SCREEN */

#home {
	background-image: url("../assets/bg20.jpg");
	background-size: cover;
	background-position: center;
	height: 100vh;
	background-repeat: no-repeat;
}

.profile {
	background-image: url("../assets/profile_home_2.png");
	background-size: 630px;
	background-position: 90% 20vh;
	background-repeat: no-repeat;
	min-height: 100vh;
}

.home-content {
	padding-top: 175px;
}

.text {
	padding-bottom: 40vh;
	text-transform: uppercase;
}

.text1 {
	color: white;
	font-size: 2.5rem;
	font-family: "Poppins Regular" !important;
	padding: 5px 10px 0px 10px;
}

.text2 {
	font-family: "Poppins";
	color: white;
	font-size: 3.8rem;
	padding: 3px 8px 3px 8px;
	background-image: linear-gradient(rgb(255, 101, 101), rgb(253, 67, 67));
	background-repeat: no-repeat;
	background-position: 0% 100%;
	background-size: 100% 2px;
	transition: background-size 0.4s, background-position 0.2s ease-in-out 0.4s;
}

.text2:hover {
	background-size: 100% 100%;
	background-position: 0% 0%;
	transition: background-position 0.4s, background-size 0.2s ease-in-out 0.4s;
}

.centered-text {
	position: absolute;
	font-size: 3rem;
	left: 50%;
	-webkit-transform: translate(-50%, 100%);
	transform: translate(-50%, 100%);
	padding: 5px;
	background-image: linear-gradient(rgb(255, 101, 101), rgb(253, 67, 67));
	color: white;
	text-align: center;
	white-space: nowrap;
}

@media (min-width: 991px) {
	.centered-text {
		display: none;
	}
}

@media (max-width: 1199px) {
	.text2 {
		font-size: 3.2rem;
	}
}

@media (max-width: 991px) {
	.profile {
		background-size: 540px;
		background-position: 50% 20vh;
	}
	.home-content {
		padding-top: 175px;
		padding-bottom: 150px;
	}
	.text {
		display: none;
	}
	.centered-text {
		font-size: 2.3rem;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

@media (max-width: 767px) {
	.profile {
		background-position: 75% 10vh;
	}
	.centered-text {
		font-size: 3rem;
	}

	.home-content {
		padding-top: 50px;
		padding-bottom: 100px;
	}
}

@media (max-width: 575px) {
	.centered-text {
		font-size: 2.5rem;
	}
}
