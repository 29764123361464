.top-bar {
	color: #333;
	padding-top: 250px;
	text-align: center;
	opacity: 0;
}

.top-bar h1 {
	font-size: 3rem;
	text-transform: uppercase;
	color: black;
}

.top-bar h2 {
	font-size: 2.3rem;
}

@media (max-width: 991px) {
	.top-bar {
		padding-top: 200px;
	}
	.top-bar h1 {
		font-size: 2.3rem;
	}
	.top-bar h2 {
		font-size: 1.6rem;
	}
}
