/*
* Lucas Ahlgren
* Updated: 2020-03-10
* Design inspired by the Box theme created by Ahmed Eissa
* 
*/

/* Global style
==================================================
	- Fonts
	- General styles 
    - Animations
    - Media queries
*/

/* Fonts
==================================================*/

@font-face {
	font-family: "Poppins";
	src: url("../assets/fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
	font-family: "Poppins Regular";
	src: url("../assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
	font-family: "Montserrat Extra Bold";
	src: url("../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf");
}


/* General styles
==================================================*/

html{
	font-size: 15px;
}

* {
	font-family: "Poppins", sans-serif;
	color: black;
}

body{
	overflow-x: hidden;
}

h1 {
	color: white;
	font-size: 2.5rem;
	text-transform: uppercase;
}

h2{
	font-size: 2rem;
}

p {
	font-family: "Poppins Regular";
	font-size: 1rem;
	line-height: 1.5;
}

.main-container {
	padding: 50px 0;
	opacity: 0;
	min-height: 100vh;	
}

a {
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.justify {
	text-align: justify;
	text-justify: inter-word;
}

.pink {
	color: rgb(255, 101, 101);
}

.section-bg {
	background-image: url("../assets/bg15.png");
	background-size: cover;
	height: 100vh;
	background-repeat: no-repeat;
}

.uppercase {
	text-transform: uppercase;
}

/* Animations
==================================================*/

.top-bar {
	-webkit-animation: intro 0.6s normal forwards;
	animation: intro 0.6s normal forwards;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-delay: 0.45s;
	animation-delay: 0.45s;
}
.main-container {
	-webkit-animation: fade-in 0.7s normal forwards;
	animation: fade-in 0.7s normal forwards;
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}
#home {
	-webkit-animation: fade-in 0.5s normal forwards;
	animation: fade-in 0.5s normal forwards;
}

/* Keyframes */

@-webkit-keyframes intro {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 60px, 0);
		transform: translate3d(0, 60px, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes intro {
	0% {
		-webkit-transform: translate3d(0, 60px, 0);
		transform: translate3d(0, 60px, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes fade-in {
	100% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
}

@keyframes fade-in {
	100% {
		opacity: 1;
	}
	0% {
		opacity: 0;
	}
}

/* CSS Transition group */

.fade-enter {
	opacity: 0.01;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	-webkit-transition: opacity 300ms ease-in;
	transition: opacity 300ms ease-in;
}

.fade-exit {
	opacity: 1;
	-webkit-transition: opacity 300ms ease-in;
	transition: opacity 300ms ease-in;
}

.fade-exit.fade-exit-active {
	opacity: 0.01;
	-webkit-transition: opacity 300ms ease-in;
	transition: opacity 300ms ease-in;
}

div.transition-group {
	position: relative;
}
section.route-section {
	position: absolute;
	width: 100%;
	-webkit-transform: translate(0,0);
	transform: translate(0,0);
}

/* Media queries
==================================================*/



@media only screen and (max-width: 767px) {
	.section-bg {
		background-image: url(../assets/bg_mob.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	html {
		font-size: 13px;
	}
}

@media only screen and (max-width: 520px) {
	.main-container {
		padding: 50px 0 20px 0;
	}
}
