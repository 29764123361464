/* Header
==================================================*/

.header {
	position: absolute;
	-webkit-transform: translate(0,0);
	        transform: translate(0,0);
	width: 100%;
	z-index: 5;
	padding: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	height: auto;
	background: transparent;
	-webkit-box-shadow: none;
	        box-shadow: none;
}

/* Desktop nav  */

.desktop-nav {
	padding: 5px 0 0 0;
	text-align: center;
}

.desktop-nav-list {
	display: inline;
	padding: 0;
}

.nav-link {
	position: relative;
	text-decoration: none;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.nav-link:hover {
	color: rgb(255, 101, 101) !important;
	-webkit-transition: 0.3s !important;
	transition: 0.3s !important;
}

.desktop-nav-list li {
	text-decoration: none;
	display: inline-block;
}

.desktop-nav-list[data-ishome="true"] * {
	color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.desktop-nav-list[data-ishome="false"] * {
	color: black;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

/* Logo */

.logo {
	margin-left: auto !important;
	margin-right: auto !important;
	max-width: 350px;
	margin-top: 30px;
	max-height: 50px;
}

.logo[data-ishome="true"] {
	fill: white;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.logo[data-ishome="false"] {
	fill: black;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.logo-header {
	display: block;
	text-align: center;
}

.logo-header a {
	color: white;
}

/* Mobile nav Design by Geoffrey Crofte */
.mobile-nav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	text-align: center;
	opacity: 0;
	visibility: hidden;
}

.mobile-nav[data-open="true"] {
	opacity: 1;
	z-index: 1;
	visibility: visible;
}

.mobile-nav::before {
	content: "";
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: -15px;
	background: black;
	-webkit-transform-origin: 0 0;
	        transform-origin: 0 0;
	-webkit-transform: skew(-14deg) translateX(-120%);
	        transform: skew(-14deg) translateX(-120%);
	-webkit-transition: all 0.275s 0.1s;
	transition: all 0.275s 0.1s;
}

.mobile-nav[data-open="true"]::before {
	-webkit-transform: skew(-14deg) translateX(0);
	        transform: skew(-14deg) translateX(0);
}

.mobile-nav ul {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	height: 100%;
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-transform: translateX(-14%) skew(-16deg);
	        transform: translateX(-14%) skew(-16deg);
}

.mobile-nav li {
	display: block;
	margin: 0.5rem 0;
	font-size: 1.1rem;
	text-align: right;
	-webkit-transform: skew(16deg);
	        transform: skew(16deg);
}

.mobile-nav[data-open="false"] a {
	opacity: 0;
	-webkit-transform: translateY(-15px);
	        transform: translateY(-15px);
}

.mobile-nav[data-open="true"] a {
	opacity: 1;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
}
.mobile-nav li:nth-child(1) a {
	-webkit-transition: all 475ms 255ms;
	transition: all 475ms 255ms;
}
.mobile-nav li:nth-child(2) a {
	-webkit-transition: all 475ms 335ms;
	transition: all 475ms 335ms;
}
.mobile-nav li:nth-child(3) a {
	-webkit-transition: all 475ms 415ms;
	transition: all 475ms 415ms;
}
.mobile-nav li:nth-child(4) a {
	-webkit-transition: all 475ms 495ms;
	transition: all 475ms 495ms;
}

.mobile-nav ul,
.mobile-nav li {
	list-style: none;
	padding: 0;
}
.mobile-nav a {
	display: block;
	padding: 12px 0;
	color: white;
	font-size: 1.4em;
	text-decoration: none;
	font-weight: bold;
}


/* Mobile nav trigger and menu icon */

.menu-trigger {
	position: absolute;
	width: 50px;
	background-color: #fff;
	cursor: pointer;
}

.menu-icon[data-ishome="true"][data-isclicked="false"],
.menu-icon[data-ishome="true"][data-isclicked="false"]::before,
.menu-icon[data-isHome="true"][data-isclicked="false"]::after {
	background-color: white !important;
	-webkit-transition: 0.3s ease;
	transition: 0.3s ease;
}

.menu-trigger .menu-icon {
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 50%;
	bottom: auto;
	right: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 22px;
	background-color: black;
	height: 2.5px;
	list-style: none;
	-webkit-transition: 0.3s ease;
	transition: 0.3s ease;
}

.menu-trigger .menu-icon::before,
.menu-trigger .menu-icon:after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: black;
	right: 0;
	-webkit-transition: 0.3s ease;
	transition: 0.3s ease;
}

.menu-trigger .menu-icon::before {
	top: -5px;
}

.menu-trigger .menu-icon::after {
	top: 5px;
}

.menu-trigger .menu-icon[data-isclicked="true"] {
	background-color: rgba(255, 255, 255, 0);
}

.menu-trigger .menu-icon.menu-icon[data-isclicked="true"]::before,
.menu-trigger .menu-icon.menu-icon[data-isclicked="true"]::after {
	background-color: black;
}

.menu-trigger .menu-icon.menu-icon[data-isclicked="true"]::before {
	top: 0;
	-webkit-transform: rotateZ(135deg);
	transform: rotateZ(135deg);
}

.menu-trigger .menu-icon.menu-icon[data-isclicked="true"]::after {
	top: 0;
	-webkit-transform: rotateZ(225deg);
	transform: rotateZ(225deg);
}

.menu-trigger {
	width: 100px;
	padding-left: 1em;
	background-color: transparent;
	height: 30px;
	line-height: 30px;
	right: 10px;
	top: 50%;
	bottom: auto;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.menu-trigger .menu-icon {
	left: auto;
	right: 1em;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

@media only screen and (min-width: 768px) {
	.logo:hover {
		fill: rgb(255, 101, 101);
		-webkit-transition: 0.3s;
		transition: 0.3s;
	}
	.mobile-nav {
		display: none;
	}
	.menu-trigger {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.logo-header {
		text-align: left;
	}

	.desktop-nav {
		display: none;
	}
	.menu-trigger {
		display: inline;
	}

	.logo {
		margin-left: 0 !important;
		margin-top: 0 !important;
		width: 175px;
		height: auto;
	}
	.header[data-open="true"] {
		background-color: rgba(255, 255, 255, 0.9);
		position: fixed;
		padding: 20px;
		overflow: hidden;
		-webkit-transition: all 0.275s ease-in-out 0.1s;
		transition: all 0.275s ease-in-out 0.1s;
	}
	.header[data-open="false"] {
		-webkit-transition: all 0.4s;
		transition: all 0.4s;
	}

	.logo[data-ishome="true"][data-isclicked="true"] {
		fill: black;
	}
}

@media only screen and (max-width: 670px) {
	.box-headline {
		font-size: 100px;
	}
	.box-primary-nav a {
		padding: 5px 1em;
	}
	.box-primary-nav {
		padding: 80px 0 0;
	}

	.box-primary-nav .box-social a {
		font-size: 23px;
	}
}

@media only screen and (max-width: 520px) {
	.box-headline {
		font-size: 80px;
	}
}

@media only screen and (max-width: 420px) {
	.box-headline {
		font-size: 50px;
	}
}
