.portfolio_item {
	position: relative;
	overflow: hidden;
	display: block;
}

.portfolio_item .portfolio_item_hover {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	background: -webkit-gradient(
		linear,
		left top, left bottom,
		color-stop(0, rgba(0, 0, 0, 0.5)),
		to(rgba(0, 0, 0, 1))
	) !important;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.5) 0,
		rgba(0, 0, 0, 1) 100%
	) !important;

	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.portfolio_item .portfolio_item_hover .item_info {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	padding: 10px;
	width: 100%;
	font-weight: bold;
}

.portfolio_item .portfolio_item_hover .item_info span {
	display: block;
	color: #fff;
	font-size: 18px;
	-webkit-transform: translateX(-50px);
	transform: translateX(-50px);
	-webkit-transition: all 0.2s ease 0.2s;
	transition: all 0.2s ease 0.2s;
	opacity: 0; 
}

.portfolio_item .portfolio_item_hover .item_info em {
	font-style: normal;
	display: inline-block;
	background-color: red;
	padding: 5px 20px;
	border-radius: 25px;
	color: white;
	margin-top: 10px;
	-webkit-transform: translateX(-50px);
	transform: translateX(-50px);
	-webkit-transition: all 0.3s ease-in-out 0.3s;
	transition: all 0.3s ease-in-out 0.3s;
	opacity: 0;
	font-size: 10px;
	letter-spacing: 2px;
}

.portfolio_item:hover .portfolio_item_hover {
	opacity: 1;
}

.portfolio_item:hover .item_info em,
.portfolio_item:hover .item_info span {
	opacity: 1;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}
