/* Footer
==================================================*/

footer {
	padding: 20px 0;
	text-align: center;
	background: #f5f5f5;
}

.copyright {
	margin-bottom: 0;
	font-family: "Poppins";
	font-size: 16px;
}

footer img {
	margin: 0 auto;
}

/* Social media
==================================================*/

.social-ul {
	list-style: none;
	display: inline-block;
	padding-left: 0;
}

.social-media-fill {
   fill: black;
   -webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.social-media-fill:hover {
   fill: rgb(255, 101, 101);
   -webkit-transition: all 0.3s;
	transition: all 0.3s;
}