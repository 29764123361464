/* Dates
==================================================*/
.dates {
	background-color: black;
	color: white !important;
	padding: 5px;
}

/* Timeline
==================================================*/
.timeline {
	position: relative;
	padding: 0;
	list-style: none;
}

.timeline:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 40px;
	width: 3px;
	margin-left: -1.5px;
	content: "";
	background-color: #d8d8d8;
}

.timeline > li {
	position: relative;
	min-height: 50px;
	margin-bottom: 50px;
}

.timeline > li:after,
.timeline > li:before {
	display: table;
	content: " ";
}

.timeline > li:after {
	clear: both;
}

.timeline > li .timeline-panel {
	position: relative;
	float: right;
	width: 100%;
	padding: 0 20px 0 100px;
	text-align: left;
}

.timeline > li .timeline-panel:before {
	right: auto;
	left: -15px;
	border-right-width: 15px;
	border-left-width: 0;
}

.timeline > li .timeline-panel:after {
	right: auto;
	left: -14px;
	border-right-width: 14px;
	border-left-width: 0;
}

.timeline > li .timeline-image {
	position: absolute;
	z-index: 100;
	left: 0;
	width: 70px;
	height: 70px;
	margin-left: 5px;
	text-align: center;
	color: #fff;
	border: 3px solid #d8d8d8;
	background-color: #fff;
	padding: 3px;
}

.timeline > li .timeline-image h4 {
	font-size: 10px;
	line-height: 14px;
	margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel {
	float: right;
	padding: 0 10px 0 100px;
	text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
	right: auto;
	left: -15px;
	border-right-width: 15px;
	border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
	right: auto;
	left: -14px;
	border-right-width: 14px;
	border-left-width: 0;
}

.timeline > li:last-child {
	margin-bottom: 0;
}

.timeline .timeline-heading h4 {
	margin-top: 0;
	color: inherit;
}

.timeline .timeline-heading h4.subheading {
	text-transform: none;
}

.timeline .timeline-body > p,
.timeline .timeline-body > ul {
	margin-bottom: 0;
}

/* Media queries
==================================================*/

@media (min-width: 768px) {
	.timeline:before {
		left: 25%;
	}
	.timeline > li {
		min-height: 100px;
		margin-bottom: 100px;
	}
	.timeline > li .timeline-panel {
		float: left;
		width: 55%;
		right: 13%;
		padding: 0 20px 20px 30px;
		text-align: right;
	}
	.timeline > li .timeline-image {
		left: 25%;
		width: 100px;
		height: 100px;
		margin-left: -50px;
	}
	.timeline > li .timeline-image h4 {
		font-size: 13px;
		line-height: 18px;
		margin-top: 16px;
	}
	.timeline > li.timeline-inverted > .timeline-panel {
		float: right;
		padding: 0 30px 20px 20px;
		text-align: left;
	}
}

@media (min-width: 992px) {
	.timeline > li {
		min-height: 150px;
	}
	.timeline > li .timeline-panel {
		padding: 0 20px 20px;
	}
	.timeline > li .timeline-image {
		width: 100px;
		height: 100px;
		margin-left: -50px;
	}
	.timeline > li .timeline-image h4 {
		font-size: 18px;
		line-height: 26px;
		margin-top: 30px;
	}
	.timeline > li.timeline-inverted > .timeline-panel {
		padding: 0 20px 20px;
	}
}

@media (min-width: 1200px) {
	.timeline > li {
		min-height: 170px;
	}
	.timeline > li .timeline-panel {
		padding: 0 20px 20px 100px;
	}
	.timeline > li .timeline-image {
		width: 100px;
		height: 100px;
		margin-left: -50px;
	}
	.timeline > li .timeline-image h4 {
		margin-top: 40px;
	}
	.timeline > li.timeline-inverted > .timeline-panel {
		padding: 0 100px 20px 20px;
	}
}
