/* Preloader
==================================================*/

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f5f5f5;
	/* change if the mask should be a color other than white */
	z-index: 1000;
	/* makes sure it stays on top */
}

#preloader[data-fadepreloader="true"] {
	-webkit-transition: ease-in-out 1.3s;
	transition: ease-in-out 1.3s;
	z-index: -5;
	transform: translateY(100%);
}

.logo-preloader {
	background-image: linear-gradient(black, black);
	height: auto;
	background-repeat: no-repeat;
	background-position: 0% 100%;
	background-size: 0% 5%;
	padding: 5%;
	width: 30vw;
	min-width: 500px;
	animation: preloader 1.5s ease-in-out 1s forwards;
	-webkit-animation: preloader 1.5s ease-in-out 1s forwards;
}

@keyframes preloader {
	0% {
		background-size: 0% 5%;
	}
	50% {
		background-size: 100% 5%;
		background-position: 0% 100%;
		fill: black;
	}
	100% {
		background-size: 100% 100%;
		fill: white;
	}
}

@-webkit-keyframes preloader {
	0% {
		background-size: 0% 5%;
	}
	50% {
		background-size: 100% 5%;
		background-position: 0% 100%;
		fill: black;
	}
	100% {
		background-size: 100% 100%;
		fill: white;
	}
}

.fade-in {
	opacity: 0;
	-webkit-animation: fade-in 1s;
	animation: fade-in 1s;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.pre-container {
	position: absolute;
	left: 50%;
	top: 45%;
	bottom: auto;
	right: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
}

@media (max-width: 991px) {
	.logo-preloader {
		width: 70vw;
		max-width: 400px;
		min-width: 0px;
	}
}
